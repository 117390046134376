<template>
  <div class="edit" oncontextmenu="return false" onselectstart="return false">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="test-title">
        <strong>{{ listInfo.title }}</strong>
        <!-- <span>{{time}}</span> -->
      </div>
      <!-- 答题 -->
      <div v-if="!showRemark">
        <div class="h3">第 {{ question.number }} 题</div>
        <!-- <div class="h3">题项：</div> -->
        <div class="question" v-html="question.subject"></div>
        <ul class="answer-option">
          <li v-for="item in question.items" :key="item.id">
            <input
              type="radio"
              :id="'answer' + item.id"
              :name="'ques' + item.ques_id"
              :value="item.symbol"
              v-model="answer"
            />
            <label :for="'answer' + item.id"
              ><b>{{ item.symbol }}：</b>
              <div class="answer-info" v-html="item.content"></div>
            </label>
          </li>
        </ul>
        <div class="replenish" v-if="tid == 6">
          <p>补充说明小孩的状况：</p>
          <textarea class="remark" v-model="remark" rows="4"></textarea>
        </div>
        <div class="btn-box">
          <button type="button" @click="prevStep()" v-if="question.number != 1">
            上一题
          </button>
          <button type="button" @click="nextStep()">下一题</button>
        </div>
      </div>
      <!-- 补充说明 -->
      <div v-show="showRemark">
        <div class="h3" style="line-height: 28px">
          <span style="">补充说明</span>
          <p style="font-size: 14px; line-height: 22px; margin-top: 5px">
            (有关个案的其他说明，由施测人员填写。例如：是否有特殊表现、是否有特殊诊断、表现最差的部分、家长的困扰，或是施测人员的建议与说明。)
          </p>
        </div>
        <div class="replenish">
          <textarea class="remark" v-model="remarkEnd" rows="4" p=""></textarea>
        </div>
        <div class="btn-box">
          <button type="button" @click="submitRemark">提交</button>
        </div>
      </div>
    </div>
    <!-- 作答弹窗说明 -->
    <div class="model" v-if="explainShow">
      <h2>作答说明</h2>
      <div class="model-content">
        <p style="padding-left: 30px; text-indent: -30px">
          一、填答者必须与儿童相处六个月以上，填答这些问题可以协助您了解儿童的感觉统合功能各项问题，请务必详细观察题项所描述之行为与行为发生的频率后再填答。
        </p>
        <p style="padding-left: 30px; text-indent: -30px">
          二、不可在匆忙的状况下填答，也不要在吵杂的环境中填答。填答时间不受限，但还是请您尽快填答完毕。
        </p>
      </div>
      <div class="model-btn">
        <span @click="closeWindow">关闭</span>
      </div>
    </div>
    <div class="model-blank" v-if="explainShow"></div>
    <!-- 弹窗说明 -->
    <div class="model" v-if="modelShow">
      <h2>指导语</h2>
      <div class="model-content child write" v-html="listInfo.guid_msg"></div>
      <div class="model-btn">
        <span @click="modelShow = false">开始答题</span>
      </div>
    </div>
    <div class="model-blank" v-if="modelShow"></div>
    <!-- 转介弹窗 -->
    <div class="model" v-if="modelShowT2">
      <h2>测评结果</h2>
      <div class="model-content child write" v-html="resultT2"></div>
      <div class="model-btn">
        <span @click="closeModelT2">确定</span>
      </div>
    </div>
    <div class="model-blank" v-if="modelShowT2"></div>
    <!--底部-->
    <footer-nav></footer-nav>
    <!-- my confirm -->
    <my-confirm ref="myComfirm"></my-confirm>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import FooterNav from "@/components/FooterNav";
import MyConfirm from "@/components/MyConfirm";

export default {
  name: "user",
  data() {
    return {
      current: "evaluation",
      loop: {},
      startTime: 0,
      time: 0,
      listInfo: {},
      question: {},
      answer: 0,
      modelShow: true,
      explainShow: false,
      tid: "",
      remark: "", //自闭量表补充说明,
      remarkEnd: "", // 量表补充说明
      showRemark: false,
      modelShowT2: false, // 达到转介提示
      resultT2: "", // 转介弹窗提示内容
    };
  },
  methods: {
    getTestInfo: function () {
      let ques_id = localStorage.getItem("qid");
      let tid = localStorage.getItem("tid");
      let params = {
        title_id: localStorage.getItem("tid"),
      };
      this.$post("exam/titleInfo", params).then((res) => {
        if (res.code === 1) {
          this.listInfo = res.data;
          if (tid == 4) {
            this.listInfo.title = "儿童写字表现评量表（学龄版）";
          } else if (tid == 3) {
            this.listInfo.title = "儿童写字表现评量表（学前版）";
          } else if (tid == 2) {
            this.listInfo.title = "儿童感觉统合功能评量表（学龄版）";
          } else if (tid == 1) {
            this.listInfo.title = "儿童感觉统合功能评量表（学前版）";
          }
          if (ques_id == 1) {
            this.explainShow = true;
          } else {
            this.modelShow = true;
          }
        }
      });
    },
    getQuestion: function () {
      let params = {
        ques_id: localStorage.getItem("qid"),
      };
      this.$post("exam/questionInfo", params).then((res) => {
        if (res.code === 1) {
          this.question = res.data;
          setTimeout(() => {
            this.answer = res.data.answer || "";
            this.remark = res.data.remark || "";
          }, 50);
          localStorage.setItem("qid", this.question.items[0].ques_id);
          // 第一题提示指导语
          if (res.data.number == 1 && params.ques_id != 1) {
            this.modelShow = true;
          }
          if (params.ques_id == 1) {
            this.explainShow = true;
          }
        }
      });
      console.log(params);
    },
    closeWindow() {
      this.explainShow = false;
      this.modelShow = true;
    },
    //上一题
    prevStep: function () {
      let params = {
        ques_id: localStorage.getItem("qid"),
        exam_id: localStorage.getItem("eid"),
        remark: this.remark || "",
        answer: this.answer || "",
      };
      this.$post("exam/prevQuestion", params).then((res) => {
        // this.$layer.msg(res.msg)
        if (res.code === 1) {
          this.question = res.data;
          setTimeout(() => {
            this.answer = res.data.answer || "";
            this.remark = res.data.remark || "";
          }, 50);
          localStorage.setItem("qid", this.question.items[0].ques_id);
        }
      });
    },
    //下一题
    nextStep: function () {
      if (!this.answer) {
        this.$layer.msg("请选择答案！");
        return false;
      }
      let params = {
        ques_id: localStorage.getItem("qid"),
        exam_id: localStorage.getItem("eid"),
        answer: this.answer,
      };
      if (this.tid == 6) {
        params.remark = this.remark;
      }
      this.$post("exam/saveAndNext", params).then((res) => {
        if (res.code === 1) {
          this.question = res.data;
          setTimeout(() => {
            this.answer = res.data.answer || "";
            this.remark = res.data.remark || "";
          }, 50);
          localStorage.setItem("qid", this.question.items[0].ques_id);
        } else if (res.code === 10) {
          // 去填写补充说明
          this.showRemark = true;
        } else if (res.code === 20) {
          this.$layer.msg(res.msg);
        } else {
          this.$layer.msg(res.msg);
        }
      });
    },
    // 提交补充说明
    submitRemark() {
      // if (!this.remarkEnd) {
      //     this.$layer.msg('请填写补充说明')
      //     return false
      // }
      let exam_id = localStorage.getItem("eid");
      let params = {
        exam_id: exam_id,
        remark: this.remarkEnd,
      };
      this.$post("exam/examRemark", params).then((res) => {
        if (res.code === 1) {
          let _id = this.$route.query.id
            ? this.$route.query.id
            : localStorage.getItem("tid");
          if (_id == 5) {
            this.vocabularyShow();
          } else {
            this.resultTips();
          }
        } else if (res.code == -1) {
          this.resultT2 = res.data.contact;
          this.modelShowT2 = true;
        } else {
          this.$layer.msg(res.msg);
        }
      });
    },
    // 关闭转介弹窗
    closeModelT2() {
      this.modelShowT2 = false;
      this.resultTips();
    },
    // pick() {
    //     let exam_id = localStorage.getItem('eid')
    //     let _id = this.$route.query.id ? this.$route.query.id : localStorage.getItem('tid')
    //     this.$router.replace('/words?eid=' + exam_id + '&id=' + _id)
    // },
    direct() {
      let exam_id = localStorage.getItem("eid");
      let _id = this.$route.query.id
        ? this.$route.query.id
        : localStorage.getItem("tid");
      let _route = "";
      if (_id == 3 || _id == 4) {
        _route = "writing";
      }
      if (_id == 1 || _id == 2) {
        _route = "sense";
      }
      if (_id == 5) {
        _route = "language";
      }
      if (_id == 6) {
        _route = "autism";
      }
      if (_id == 9) {
        _route = "sift";
      }
      if (_id == 10) {
        _route = "ydtsn";
      }
      if (_id == 11) {
        _route = "wydsgn";
      }
      if (_id == 12) {
        _route = "rzsw";
      }
      if (_id == 13) {
        _route = "gnyyfz";
      }
      if (_id == 14) {
        _route = "shqx";
      }
      if (_id == 16) {
        _route = "jthjpl";
      }
      if (_id == 17) {
        _route = "hyxfg";
      }
      if (_id == 18) {
        _route = "crtnqjtzs";
      } else {
        this.$router.replace("/");
      }
      this.$router.replace(`/${_route}?eid=` + exam_id + "&id=" + _id);
    },
    vocabularyShow() {
      let confirm = this.$refs.myComfirm;
      confirm.open(
        "温馨提示",
        {
          content: "请继续进行零岁至三岁婴幼儿词汇调查表",
          btn: ["继续作答"],
        },
        () => {
          confirm.hide();
          //     this.pick()
        }
      );
    },
    resultTips: function () {
      let exam_id = localStorage.getItem("eid");
      let confirm = this.$refs.myComfirm;
      confirm.open(
        "温馨提示",
        {
          content: "测试已完成，进入查看结果?",
          btn: ["测评历史", "查看结果"],
        },
        () => {
          confirm.hide();
          this.$router.replace("/history");
        },
        () => {
          confirm.hide();
          // this.$layer.msg('功能还在完善中，敬請期待！')
          let _id = this.$route.query.id
            ? this.$route.query.id
            : localStorage.getItem("tid");
          let _route = "";
          if (_id == 3 || _id == 4) {
            _route = "writing";
          }
          if (_id == 1 || _id == 2) {
            _route = "sense";
          }
          if (_id == 5) {
            _route = "language";
          }
          if (_id == 6) {
            _route = "autism";
          }
          if (_id == 9) {
            _route = "sift";
          }
          if (_id == 10) {
            _route = "ydtsn";
          }
          if (_id == 11) {
            _route = "wydsgn";
          }
          if (_id == 12) {
            _route = "rzsw";
          }
          if (_id == 13) {
            _route = "gnyyfz";
          }
          if (_id == 14) {
            _route = "shqx";
          }
          if (_id == 16) {
            _route = "jthjpl";
          }
          if (_id == 17) {
            _route = "hyxfg";
          }
          if (_id == 18) {
            _route = "crtnqjtzs";
          } else {
            this.$router.replace("/");
          }
          this.$router.replace(`/${_route}?eid=` + exam_id + "&id=" + _id);
        }
      );
    },
    timer: function () {
      this.startTime = new Date().getTime();
      this.loop = setInterval(() => {
        let t = new Date().getTime();
        let dt = t - this.startTime;
        let h = parseInt((dt % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let m = parseInt((dt % (1000 * 60 * 60)) / (1000 * 60));
        let s = parseInt((dt % (1000 * 60)) / 1000);
        if (s < 10) {
          s = "0" + s;
        }
        if (h) {
          this.time = h + " : " + m + " : " + s;
        } else {
          this.time = m + " : " + s;
        }
      }, 1000);
    },
  },
  components: {
    HeaderNav,
    FooterNav,
    MyConfirm,
  },
  mounted: function () {
    var h = document.documentElement.clientHeight || document.body.clientHeight;
    this.$refs.content.style.minHeight = h - 144 + "px";
  },
  created() {
    this.tid = localStorage.getItem("tid");
    if (localStorage.getItem("tid")) {
      this.getTestInfo();
    }
    if (localStorage.getItem("qid")) {
      this.getQuestion();
    }
    this.timer();
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/css/page/test");

.remark {
  font-size: 16px;
  padding: 10px;
  line-height: 1.6;
  width: 100%;
  box-sizing: border-box;
}
</style>
